import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../../components/Layout';
import useWindowSize from '../../../helpers/useWindowSize';

const Uniform = ({ data }) => {
  const [width, height] = useWindowSize();
  return (
    <Layout>
      <section className='uniform'>
        <h1>UNIform - speculative design</h1>
        <h2>
          <Link to='/work'>2018/design</Link>
        </h2>

        <Img
          fluid={data.image1.childImageSharp.fluid}
          alt='united nations uniform'
          className='topimage'
        />

        <Img
          fluid={data.image2.childImageSharp.fluid}
          alt='website'
          className='full'
        />

        <div className='mid-section'>
          <Link
            to='https://xd.adobe.com/view/8441daa4-3e68-4394-5fce-4cd620b71e8b-f270/?fullscreen'
            target='_blank'
            className='leftimage'
          >
            <Img
              fluid={data.image3.childImageSharp.fluid}
              alt='woman standing'
            />
          </Link>
          <p className='maintext'>
            This project started with a question.
            <br />
            What if in the future everyone had to wear the same uniform?
            <br />
            <br />
            That lead us to «UNIform».
            <br />
            <br />A concept showcasing a speculative future, through the
            uniforms website.
            <br />
            <br />
            Click the picture to the left to visit website.
          </p>
          <p className='extratext'>
            We also wanted to explore how UNIform would affect the youths need
            to express themselves. So we wrote a interview with Angelo Rayz
            about expressing your style in the year 2122.
            <br />
            <br />
            Click the picture to the right to visit website.
          </p>

          <Link
            target='_blank'
            to='https://xd.adobe.com/view/78042ab9-8507-4628-5535-e193acab10fa-0e35/?fullscreen'
          >
            <Img
              fluid={data.image4.childImageSharp.fluid}
              alt='website showing happy model'
              className='rightimage'
              style={{
                position: width > 1100 ? 'absolute' : 'relative',
              }}
            />
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query uniformimages {
    image1: file(relativePath: { eq: "uniform/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "uniform/2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "uniform/3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "uniform/4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Uniform;
